<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Account Setting</h1></div>
    </div>
    <div class="row">
      <div class="col-md-3 d-none d-md-block" id="menuWrapper">
        <AccountSettingMenu activeMenu="security" />
      </div>
      <div class="col-md-9">
        <SettingTitle title="Security" />
        <div class="card">
          <div class="card-header">Change Password</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <label for="oldPassword" class="form-label">Old Password</label>
                <input
                  type="text"
                  class="form-control"
                  id="oldPassword"
                  placeholder="Old Password"
                />
              </div>
              <div class="col-md-4">
                <label for="newPassword" class="form-label">New Password</label>
                <input
                  type="text"
                  class="form-control"
                  id="newPassword"
                  placeholder="New Password"
                />
              </div>
              <div class="col-md-4">
                <label for="confirmPassword" class="form-label">Confirm Password</label>
                <input
                  type="text"
                  class="form-control"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12"><button class="btn btn-primary">Update password</button></div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">Login Notifications</div>
          <div class="card-body">
            <div class="card-text">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" checked />
                <label class="form-check-label"> Turn on login notifications </label>
              </div>
              <p>
                Login notifications are an extra security feature. When you turn them on, we'll let
                you know when anyone logs into your Trepr account from a new browser. This helps
                keep your account safe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingMenu from './Menu';
import SettingTitle from './SettingTitle';
export default {
  name: 'AccountSetting',
  components: { AccountSettingMenu, SettingTitle },
};
</script>

<style scoped></style>
